<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAdd" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Add Campus</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('false')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nama campus"
                  required
                  outlined
                  dense
                  rounded
                  :counter="100"
                  v-model="nameCampus"
                  :error-messages="errMsg"
                  @input="$v.nameCampus.$touch()"
                  @blur="$v.nameCampus.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="addCampus">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "addCampus",
  mixins: [validationMixin],
  validations: {
    nameCampus: {
      required,
      maxLength: maxLength(100)
    }
  },
  props: ["dialogAdd"],
  computed: {
    errMsg() {
      const errors = [];
      if (!this.$v.nameCampus.$dirty) return errors;
      !this.$v.nameCampus.maxLength &&
        errors.push("Nama campus maksimal 100 karakter!");
      !this.$v.nameCampus.required && errors.push("Nama campus harus di isi!");
      return errors;
    }
  },
  data() {
    return {
      nameCampus: ""
    };
  },
  methods: {
    addCampus() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store
          .dispatch("campus/createUniversitas", {
            nama: this.nameCampus
          })
          .then(data => {
            if (data.data.status == "success") {
              this.nameCampus = "";
              this.$v.$reset();
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: data.data.message,
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            } else {
              Swal.fire({
                icon: "error",
                title: data.data.data.errors.nama[0],
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            }
          });
      }
    }
  }
};
</script>

<style></style>
