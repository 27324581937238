<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card class="radius-card _60-w" :loading="loading" min-height="150px">
        <v-toolbar flat dense> Campus </v-toolbar>
        <v-card-title class="pa-3">
          <v-btn outlined rounded color="blue" @click="dialogAdd = true"
            ><v-icon>mdi-plus</v-icon> Add Campus</v-btn
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            dense
            single-line
            solo
            rounded
            hide-details
          ></v-text-field>
        </v-card-title>
        <AddCampus @false="closeDialog" v-bind:dialogAdd="dialogAdd" />

        <div class="mt-10" v-if="universitas">
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="universitas.data"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="cyan"
                  class="mr-2"
                  @click.prevent="updateCampus(item)"
                >
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  color="red"
                  @click.prevent="deleteCampus(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :total-visible="7"
              @input="fetchCampus"
              :length="universitas.meta.last_page"
            ></v-pagination>
          </div>
          <div class="text-center pa-2">
            <UpdateCampus
              @false="closeDialog"
              v-bind:dialogUpdate="dialogUpdate"
              v-bind:campus="campus"
            />
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import AddCampus from "../../components/Admin/Modal/addCampus.vue";
import UpdateCampus from "../../components/Admin/Modal/updateCampus.vue";

export default {
  name: "campusManagement",

  components: {
    AddCampus,
    UpdateCampus
  },

  computed: {
    ...mapState({
      universitas: state => state.campus.universitas
    })
  },

  data() {
    return {
      dialogAdd: false,
      dialogUpdate: false,
      campus: null,
      search: "",
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "NAME", value: "nama" },
        { text: "ACTIONS", value: "actions" }
      ],
      page: 1,
      perPage: 10
    };
  },

  mounted() {
    this.fetchCampus(); //get all data campus when load page
  },

  methods: {
    fetchCampus() {
      //get all data campus fucntion
      this.loading = true;
      this.$store.dispatch("campus/listUniversitas").then(() => {
        this.loading = false;
      });
    },

    updateCampus(item) {
      this.campus = item;
      this.dialogUpdate = true;
    },

    deleteCampus(item) {
      //delete campus function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete campus
          this.$store
            .dispatch("campus/deleteUniversitas", { id: item.id })
            .then(() => {
              this.fetchCampus(); //if success refetch data all campus
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.dialogAdd = false; //set variable dialog to false
      this.dialogUpdate = false; //set variable dialog to false
      this.fetchCampus(); //and refetch data campus
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
</style>
